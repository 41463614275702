@layer base {
  .searchContainer {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  align-items: center;
  margin: var(--sk-space-40) auto 0;
  padding: var(--sk-space-8);
  border-radius: 14px;
  background-color: white;
}

.inputContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--sk-space-12) 0 var(--sk-space-20);
}

.searchIcon {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  color: var(--sk-color-black);
}

.searchButton {
  width: 100%;
}

.searchInput,
.searchInput::placeholder {
  flex: 1;
  border: none;
  outline: none;
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  text-overflow: ellipsis;
}

.searchInput {
  margin: 0 var(--sk-space-16) 0 var(--sk-space-8);
  color: var(--sk-color-black);
}

@media (--sk-medium-viewport) {
  .searchContainer {
    flex-direction: row;
    margin: var(--sk-space-80) 0 0;
    padding: var(--sk-space-2) var(--sk-space-2) var(--sk-space-2) var(--sk-space-16);
  }

  .inputContainer {
    flex-grow: 1;
    width: auto;
    padding: 0;
  }

  .searchButton {
    width: auto;
  }
}

@media (--sk-large-viewport) {
  .searchContainer {
    max-width: 600px;
    margin: var(--sk-space-80) auto 0;
  }
}

}
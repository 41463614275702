@layer modules, ui, base;
@layer base {
  .SearchBar_searchContainer__fH14L {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  align-items: center;
  margin: var(--sk-space-40) auto 0;
  padding: var(--sk-space-8);
  border-radius: 14px;
  background-color: white;
}

.SearchBar_inputContainer__HPEYt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--sk-space-12) 0 var(--sk-space-20);
}

.SearchBar_searchIcon__79a42 {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  color: var(--sk-color-black);
}

.SearchBar_searchButton__8mQJ3 {
  width: 100%;
}

.SearchBar_searchInput__XHRQX::-moz-placeholder {
  flex: 1;
  border: none;
  outline: none;
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  text-overflow: ellipsis;
}

.SearchBar_searchInput__XHRQX,
.SearchBar_searchInput__XHRQX::placeholder {
  flex: 1;
  border: none;
  outline: none;
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  text-overflow: ellipsis;
}

.SearchBar_searchInput__XHRQX {
  margin: 0 var(--sk-space-16) 0 var(--sk-space-8);
  color: var(--sk-color-black);
}

@media (min-width: 768px) {
  .SearchBar_searchContainer__fH14L {
    flex-direction: row;
    margin: var(--sk-space-80) 0 0;
    padding: var(--sk-space-2) var(--sk-space-2) var(--sk-space-2) var(--sk-space-16);
  }

  .SearchBar_inputContainer__HPEYt {
    flex-grow: 1;
    width: auto;
    padding: 0;
  }

  .SearchBar_searchButton__8mQJ3 {
    width: auto;
  }
}

@media (min-width: 1312px) {
  .SearchBar_searchContainer__fH14L {
    max-width: 600px;
    margin: var(--sk-space-80) auto 0;
  }
}

}
@layer base {
  .VideoPlayer_wrapper__KxV3Q {
  --video-width: 382px;
  --video-height: 208px;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  max-width: var(--video-width);
  max-height: var(--video-height);
  border: 1px solid var(--sk-color-white);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-black);
  text-align: center;
  cursor: pointer;
}

.VideoPlayer_icon__fMxm_ {
  position: absolute;
  z-index: var(--z-above-ui-kit-video-player);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoPlayer_thumbnailWrapper__SSj5U {
  position: relative;
  margin: 0;
}

.VideoPlayer_thumbnail__AgK96 {
  display: block;
  width: 100%;
  max-width: var(--video-width);
  height: 100%;
  max-height: var(--video-height);
  border-radius: var(--sk-radius-12);
}

.VideoPlayer_videoWrapper__GYF4M {
  overflow: hidden;
}

.VideoPlayer_video__D8iDF {
  inset: 0;
}

.VideoPlayer_video__D8iDF iframe {
  width: 100%;
  max-width: var(--video-width);
  height: 100%;
  max-height: var(--video-height);
  border-radius: var(--sk-radius-12);
}

@media (min-width: 1312px) {
  .VideoPlayer_wrapper__KxV3Q {
    --video-width: 541px;
    --video-height: 309px;
    width: var(--video-width);
    height: var(--video-height);
  }

  .VideoPlayer_thumbnail__AgK96 {
    width: var(--video-width);
    height: var(--video-height);
  }

  .VideoPlayer_video__D8iDF iframe {
    width: var(--video-width);
    height: var(--video-height);
  }
}

}
@layer base {
  .Section_main__q5HVN {
  display: flex;
  position: relative;
  background-color: var(--sk-color-white);
}

.Section_main__isBlack__WSQx1 {
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

.Section_wrapper__2Bj6k {
  box-sizing: border-box;
  padding: 0 var(--sk-space-24);
}

.Section_wrapper__withMaxWidth__NM_lw {
  width: 100%;
  max-width: 1160px;
  margin: var(--sk-space-64) auto;
}

.Section_wrapper__withBackground__F4aYx {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .Section_wrapper__2Bj6k {
    display: grid;
    flex: 1 0 50%;
  }

  .Section_wrapper__isSmall__fe6E2 {
    flex-basis: 50%;
  }

  .Section_wrapper__withMaxWidth__NM_lw {
    flex-basis: 100% !important;
  }
}

@media (min-width: 1312px) {
  .Section_wrapper__2Bj6k {
    flex-basis: 58%;
  }

  .Section_wrapper__isSmall__fe6E2 {
    flex-basis: 42%;
  }
}

}
@layer base {
  .QuestionCard_wrapper__kLlit {
  background-color: var(--sk-color-grey-050);
}

.QuestionCard_main__1gmik {
  --layout-size: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: var(--layout-size);
  padding: var(--sk-space-64) var(--sk-space-16);
  color: var(--sk-color-white);
}

.QuestionCard_cardsWrapper__yfQbk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sk-space-16);
}

.QuestionCard_card__mBRhS {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--sk-radius-12);
  background-color: white;
  color: black;
}

.QuestionCard_cardImage__O_G2u {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.QuestionCard_cardTextContent__cLB6n {
  display: flex;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--sk-space-24) var(--sk-space-16);
}

.QuestionCard_cardDescription__O2Xve {
  padding: var(--sk-space-8) 0 var(--sk-space-12) 0;
}

.QuestionCard_cardButton__XmZPO {
  width: 100%;
  font-size: var(--sk-font-size-1);
  line-height: var(--sk-line-heights-4);
}

.QuestionCard_cardPicto__85x_9 {
  position: absolute;
}

.QuestionCard_cardPictoBuilding__vTWtz {
  right: -30px;
  bottom: -43px;
}

.QuestionCard_cardPictoArrow__Nb8SF {
  right: -30px;
  bottom: -50px;
  transform: rotate(-20deg);
}

@media (min-width: 768px) {
  .QuestionCard_main__1gmik {
    --layout-size: 77rem;
    max-width: var(--layout-size);
    margin: auto;
    padding: var(--sk-space-64) var(--sk-space-40);
  }

  .QuestionCard_cardsWrapper__yfQbk {
    flex-direction: row;
  }

  .QuestionCard_card__mBRhS {
    flex-direction: row;
  }

  .QuestionCard_cardTextContent__cLB6n {
    padding: var(--sk-space-32) var(--sk-space-16);
  }

  .QuestionCard_cardButton__XmZPO {
    width: auto;
  }

  .QuestionCard_cardImage__O_G2u {
    width: 150px;
    height: 100%;
  }
}

@media (min-width: 1312px) {
  .QuestionCard_main__1gmik {
    --layout-size: 77rem;
    width: 100%;
    max-width: var(--layout-size);
    margin: auto;
    padding: var(--sk-space-64) var(--sk-space-40);
  }

  .QuestionCard_cardTextContent__cLB6n {
    padding: var(--sk-space-24);
  }

  .QuestionCard_cardImageContainer__ivLIL {
    display: flex;
    width: 100%;
    max-width: 235px;
  }

  .QuestionCard_cardImage__O_G2u {
    width: inherit;
  }
}

}
@layer base {
  .Video_wrapper__wrdVj {
  background-color: var(--sk-color-black);
}

.Video_main__6u5tx {
  --layout-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: var(--layout-size);
  padding: var(--sk-space-80) var(--sk-space-16);
  overflow-x: clip;
  color: var(--sk-color-white);
}

.Video_descriptionFirstPart__o_Yma,
.Video_descriptionSecondPart__2SxPF {
  font-style: italic;
  font-weight: var(--sk-font-weights-poppins-2); /* 400 */
}

.Video_descriptionFirstPart__o_Yma {
  margin-bottom: var(--sk-space-24);
}

.Video_textBlock__KEVTe {
  margin-bottom: var(--sk-space-40);
}

.Video_quotes__KalY9 {
  position: absolute;
  right: -53px;
  bottom: 155px;
  width: 212px;
  height: 203px;
}

@media (min-width: 768px) {
  .Video_main__6u5tx {
    --layout-size: 77rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding: 0;
    padding: var(--sk-space-80) var(--sk-space-40);
    overflow-y: clip;
    gap: var(--sk-space-80);
  }

  .Video_textBlock__KEVTe {
    width: 41%;
    margin-bottom: 0;
  }

  .Video_quotes__KalY9 {
    bottom: -80px;
    left: -5px;
  }
}

@media (min-width: 1312px) {
  .Video_main__6u5tx {
    padding: 120px 128px;
  }

  .Video_descriptionFirstPart__o_Yma,
  .Video_descriptionSecondPart__2SxPF {
    font-size: var(--sk-font-size-4); /* 20px */
  }

  .Video_quotes__KalY9 {
    bottom: 0;
    left: 0;
  }
}

}
@layer base {
  .YoungFuture_main__CEh82 {
  padding: 0 var(--sk-space-16);
  padding-top: var(--sk-space-80);
}

.YoungFuture_titleWrapper__wrxVg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--sk-space-80);
  text-align: center;
}

.YoungFuture_arrow__zHaO9 {
  margin: var(--sk-space-8) auto 0;
}

.YoungFuture_block__JNnsi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}

.YoungFuture_blockImage__6R9I5 {
  width: 100%;
  max-width: 382px;
  height: 100%;
  max-height: 286px;
  -o-object-fit: contain;
     object-fit: contain;
}

.YoungFuture_blockTitle__qsUFX {
  margin-top: var(--sk-space-40);
}

.YoungFuture_blockDescription__Dy24m {
  padding-top: var(--sk-space-16);
}

.YoungFuture_blockButton___w9mm {
  width: 100%;
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .YoungFuture_main__CEh82 {
    padding-top: 104px;
  }

  .YoungFuture_titleWrapper__wrxVg {
    flex-direction: row;
    align-items: center;
    margin: 0 145px 104px;
  }

  .YoungFuture_arrow__zHaO9 {
    width: 98px;
    height: 66px;
    margin: 0;
  }

  .YoungFuture_block__JNnsi {
    flex-direction: row;
  }

  .YoungFuture_blockTitle__qsUFX {
    margin-top: 0;
  }

  .YoungFuture_blockButton___w9mm {
    width: auto;
  }

  .YoungFuture_blockTextContent__4cGIg {
    width: 305px;
  }

  .YoungFuture_primaryText__FVrzc {
    margin-left: var(--sk-space-80);
  }

  .YoungFuture_secondaryText__QU3Tp {
    margin-right: var(--sk-space-80);
  }

  .YoungFuture_pictureOnTheRightOnMediumLargeScreen__nNESh {
    order: 2;
  }

  .YoungFuture_textOnTheLefttOnMediumLargeScreen__S8GW_ {
    order: 1;
  }
}

@media (min-width: 1312px) {
  .YoungFuture_titleWrapper__wrxVg {
    max-width: 730px;
    margin: 0 auto 104px;
  }

  .YoungFuture_arrow__zHaO9 {
    width: 130px;
    height: 88px;
    margin: 0;
  }

  .YoungFuture_blockTextContent__4cGIg {
    width: 400px;
  }

  .YoungFuture_blockButton___w9mm {
    width: auto;
  }

  .YoungFuture_blockImage__6R9I5 {
    max-width: 608px;
    max-height: 400px;
  }

  .YoungFuture_primaryText__FVrzc {
    margin-left: 120px;
  }

  .YoungFuture_secondaryText__QU3Tp {
    margin-right: 120px;
  }
}

}
@layer base {
  .PreFilteredJobSearch_wrapper__POAdt {
  background-color: var(--sk-color-black);
}

.PreFilteredJobSearch_main__8mkkE {
  --layout-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--layout-size);
  padding: var(--sk-size-56) var(--sk-space-16);
  color: var(--sk-color-white);
}

.PreFilteredJobSearch_heading__NCpCC {
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.PreFilteredJobSearch_cardContainer__xIIlq {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  justify-content: center;
  width: 100%;
  max-width: 382px;
  margin: 0 auto;
  padding-left: 0;
  gap: var(--sk-space-12);
}

.PreFilteredJobSearch_cardItem__FPFDD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--sk-size-56);
  padding: var(--sk-space-20) var(--sk-space-32);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  cursor: pointer;
  gap: 10px;
}

.PreFilteredJobSearch_cardItemWhite__4Jnkc {
  background-color: var(--sk-color-white);
}

.PreFilteredJobSearch_cardItemGreen__BSmed {
  background-color: var(--sk-color-green-500);
}

.PreFilteredJobSearch_cardItemGreen__BSmed .PreFilteredJobSearch_link__B1Eoe,
.PreFilteredJobSearch_cardItemWhite__4Jnkc .PreFilteredJobSearch_link__B1Eoe {
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-polysans);
  text-decoration: none;
}

.PreFilteredJobSearch_cardItemWhite__4Jnkc svg,
.PreFilteredJobSearch_cardItemGreen__BSmed svg {
  flex-shrink: 0;
}

/* Hide extra items for mobile (show only 3) */
.PreFilteredJobSearch_cardItemWhite__4Jnkc:nth-child(n + 4) {
  display: none;
}

@media (min-width: 768px) {
  .PreFilteredJobSearch_cardContainer__xIIlq {
    grid-template-columns: repeat(3, 1fr);
    max-width: 626px;
  }

  /* (show only 5 white cards) */
  .PreFilteredJobSearch_cardItemWhite__4Jnkc:nth-child(n + 1):nth-child(-n + 5) {
    display: flex;
  }

  .PreFilteredJobSearch_cardItemWhite__4Jnkc,
  .PreFilteredJobSearch_cardItemGreen__BSmed {
    padding: var(--sk-space-32) var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .PreFilteredJobSearch_cardContainer__xIIlq {
    grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
    max-width: 1024px;
  }

  /* (show only 11 white cards) */
  .PreFilteredJobSearch_cardItemWhite__4Jnkc:nth-child(n + 1):nth-child(-n + 11) {
    display: flex;
  }

  .PreFilteredJobSearch_cardItemWhite__4Jnkc,
  .PreFilteredJobSearch_cardItemGreen__BSmed {
    padding: var(--sk-space-32) var(--sk-space-20);
  }
}

}
@layer base {
  .Content_main__Iv4Zl {
  background-color: var(--sk-color-black);
}
/* ******************* */
/* NEW HOME PAGE CODE  */
/* ******************* */

.Content_newMain__bd1y8 {
  width: 100%;
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Content_newMain__bd1y8 {
    margin: calc(var(--sk-space-64) + var(--sk-space-40)) auto 0;
  }
}

}
@layer base {
  .Apply_main__jQfGC {
  display: block;
  padding-bottom: var(--sk-space-64);
}

.Apply_cover__0IQ97 {
  height: 30rem;
}

.Apply_section__ncqbU {
  box-sizing: border-box;
  padding: var(--sk-space-64) var(--sk-space-24);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
}

.Apply_section__ncqbU::after {
  display: none;
}

.Apply_content__ccOvA {
  z-index: var(--z-up-in-the-current-stacking-context);
  max-width: 435px;
  margin: 0 auto;
  padding: 0 var(--sk-space-24);
}

.Apply_title__CV1Mu {
  margin-bottom: var(--sk-space-32);
}

.Apply_title__CV1Mu::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.Apply_footer__1Tnzc {
  display: inline-flex;
  gap: var(--sk-space-16);
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Apply_main__jQfGC {
    display: flex;
  }

  .Apply_section__ncqbU {
    flex-basis: 57%;
    padding: var(--sk-space-64) 0;
  }

  .Apply_cover__0IQ97 {
    flex-basis: 43%;
    height: auto;
    background-position: 45% 40%;
  }

  .Apply_content__ccOvA {
    max-width: 300px;
    padding: 0;
  }
}

@media (min-width: 1312px) {
  .Apply_cover__0IQ97 {
    height: 42.5rem;
    background-position: 150% 0;
    background-size: 580px auto;
  }

  .Apply_section__ncqbU {
    position: relative;
  }

  .Apply_section__ncqbU::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 425px solid;
    border-left: 530px solid rgb(0 0 0 / 0%);
  }

  .Apply_content__ccOvA {
    margin: 0 0 0 136px;
  }
}

}
@layer base {
  .Corporate_main__HKnXp {
  background-color: var(--sk-color-grey-025);
}

.Corporate_wrapper__y6GU2 {
  display: grid;
  gap: var(--sk-space-32);
}

.Corporate_card__0Bo__ {
  position: relative;
  box-sizing: border-box;
  min-height: 210px;
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  text-align: left;
}

.Corporate_card__0Bo__::before {
  content: "";
  display: block;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  clip-path: polygon(0 0, 30% 0, 85% 100%, 0 100%);
  width: 160px;
  height: 100%;
  float: left;
  overflow: hidden;
  border-radius: inherit;
  background: no-repeat 90% 0;
  background-image: inherit;
  background-size: cover;
  shape-outside: polygon(0 0, 45% 0, 100% 100%, 0 100%);
}

.Corporate_card__0Bo__::after {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--sk-color-white);
}

.Corporate_content__xlU3b {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  box-sizing: border-box;
  height: 100%;
  padding: var(--sk-space-24);
}

.Corporate_text__x6fTF {
  max-width: 90%;
  margin: var(--sk-space-12) 0 var(--sk-space-24) 0;
  color: var(--sk-color-grey-600);
}

.Corporate_link__3cG7M::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: 0;
}

@media (min-width: 768px) {
  .Corporate_card__0Bo__::before {
    clip-path: polygon(0 0, 60% 0, 95% 100%, 0 100%);
    shape-outside: polygon(0 0, 75% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 1312px) {
  .Corporate_wrapper__y6GU2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .Corporate_card__0Bo__::before {
    width: 280px;
    clip-path: polygon(0 0, 30% 0, 85% 100%, 0 100%);
    background-position: 0 70%;
    background-size: 90%;
    shape-outside: polygon(0 0, 45% 0, 100% 100%, 0 100%);
  }
}

}
@layer base {
  .Deepen_main__02Gjx {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sk-space-64);
}

.Deepen_section__a_jj4 {
  display: flex;
  justify-content: center;
}

.Deepen_title__uhxUv {
  margin-bottom: var(--sk-space-32);
}

.Deepen_title__uhxUv::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  color: var(--sk-color-green-500);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.Deepen_content__uWbVv {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  margin: var(--sk-space-64) 0;
}

.Deepen_cover__s7ARE {
  position: relative;
  min-height: 405px;
  background-position: top center;
  background-size: 85%;
}

.Deepen_footer__LBVhf {
  display: inline-flex;
  gap: var(--sk-space-20);
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Deepen_main__02Gjx {
    flex-direction: row;
    padding-bottom: calc(var(--sk-space-80) + var(--sk-space-16));
  }

  .Deepen_content__uWbVv {
    justify-content: center;
    max-width: 312px;
    margin-top: var(--sk-space-40);
  }

  .Deepen_cover__s7ARE {
    background-position: top left;
    background-size: 480px auto;
  }
}

@media (min-width: 1312px) {
  .Deepen_main__02Gjx {
    position: relative;
    padding-bottom: calc(var(--sk-space-64) * 2);
  }

  .Deepen_main__02Gjx::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 215px solid var(--sk-color-green-500);
    border-right: 155px solid rgb(0 0 0 / 0%);
  }

  .Deepen_section__a_jj4 {
    justify-content: flex-end;
  }

  .Deepen_content__uWbVv {
    max-width: 364px;
    padding-right: 100px;
  }
}

}
@layer base {
  .Explore_main__HpNnv {
  display: flex;
  flex-direction: column;
}

.Explore_section__4CxGP {
  display: flex;
  justify-content: center;
}

.Explore_title__BFSLt {
  margin-bottom: var(--sk-space-32);
}

.Explore_title__BFSLt::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  color: var(--sk-color-green-500);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.Explore_content__H4_LH {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  margin-top: var(--sk-space-64);
}

.Explore_cover__Og01a {
  min-height: 600px;
}

.Explore_footer__KQyeu {
  display: inline-flex;
  gap: var(--sk-space-20);
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Explore_main__HpNnv {
    flex-direction: row;
  }

  .Explore_content__H4_LH {
    justify-content: center;
    max-width: 312px;
    margin-top: 0;
  }
}

@media (min-width: 1312px) {
  .Explore_section__4CxGP {
    justify-content: flex-start;
  }

  .Explore_content__H4_LH {
    max-width: 364px;
    padding-left: 100px;
  }
}

}
@layer base {
  .Links_list__MtvL5 {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 var(--sk-space-20);
  margin: var(--sk-space-24) 0 0 0;
  padding: 0;
  list-style: none;
}

.Links_radio__V0yOY {
  display: none;
}

.Links_label__QHStq {
  cursor: pointer;
}

.Links_tabs__Y48m4 {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  margin: var(--sk-space-12) 0 0 0;
  padding: 0;
  list-style: none;
  gap: var(--sk-space-24);
}

.Links_tab__YNIk1 {
  padding: var(--sk-space-16) 0;
  border-bottom: var(--sk-space-4) solid transparent;
  cursor: pointer;
}

.Links_tab__YNIk1:hover,
.Links_secondaryTab__vF5g_:checked ~ .Links_tabnav__tpgcW .Links_tab__YNIk1:nth-child(1):hover {
  border-color: var(--sk-color-grey-200);
}

.Links_tab__YNIk1:nth-child(1),
.Links_primaryTab__6O1n1:checked ~ .Links_tabnav__tpgcW .Links_tab__YNIk1:nth-child(1),
.Links_secondaryTab__vF5g_:checked ~ .Links_tabnav__tpgcW .Links_tab__YNIk1:nth-child(2) {
  border-color: var(--sk-color-black);
}

.Links_secondaryTab__vF5g_:checked ~ .Links_tabnav__tpgcW .Links_tab__YNIk1:nth-child(1) {
  border-color: transparent;
}

.Links_list__MtvL5:nth-child(1),
.Links_primaryTab__6O1n1:checked ~ .Links_content__n6eWI .Links_list__MtvL5:nth-child(1),
.Links_secondaryTab__vF5g_:checked ~ .Links_content__n6eWI .Links_list__MtvL5:nth-child(2) {
  display: grid;
}

.Links_primaryTab__6O1n1:checked ~ .Links_content__n6eWI .Links_list__MtvL5:nth-child(2),
.Links_secondaryTab__vF5g_:checked ~ .Links_content__n6eWI .Links_list__MtvL5:nth-child(1) {
  display: none;
}

@media (min-width: 768px) {
  .Links_list__MtvL5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1312px) {
  .Links_list__MtvL5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

}
@layer base {
  .TagLine_content__q1Clo {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 420px;
  margin: var(--sk-space-64) auto;
}

.TagLine_text__jFYGn {
  margin-bottom: var(--sk-space-24);
  font-size: var(--sk-typography-display-2-font-size);
  line-height: var(--sk-typography-display-2-line-height);
}

.TagLine_button__8qTBZ {
  align-self: flex-start;
  order: -1;
  margin-top: var(--sk-space-48);
}

.TagLine_cover__dvbv2 {
  display: none;
}

@media (min-width: 768px) {
  .TagLine_content__q1Clo {
    max-width: 370px;
  }

  .TagLine_cover__dvbv2 {
    display: block;
    background-position: center right;
  }
}

@media (min-width: 1312px) {
  .TagLine_content__q1Clo {
    justify-self: flex-end;
    width: 100%;
    max-width: 564px;
    margin: 160px 0;
    padding: 0 136px 0 0;
  }

  .TagLine_text__jFYGn {
    margin-bottom: var(--sk-space-24);
    font-size: 60px;
    line-height: 75px;
  }
}

}

@layer base {
  .wrapper {
  background-color: var(--sk-color-black);
}

.main {
  --layout-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: var(--layout-size);
  padding: var(--sk-space-80) var(--sk-space-16);
  overflow-x: clip;
  color: var(--sk-color-white);
}

.descriptionFirstPart,
.descriptionSecondPart {
  font-style: italic;
  font-weight: var(--sk-font-weights-poppins-2); /* 400 */
}

.descriptionFirstPart {
  margin-bottom: var(--sk-space-24);
}

.textBlock {
  margin-bottom: var(--sk-space-40);
}

.quotes {
  position: absolute;
  right: -53px;
  bottom: 155px;
  width: 212px;
  height: 203px;
}

@media (--sk-medium-viewport) {
  .main {
    --layout-size: 77rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding: 0;
    padding: var(--sk-space-80) var(--sk-space-40);
    overflow-y: clip;
    gap: var(--sk-space-80);
  }

  .textBlock {
    width: 41%;
    margin-bottom: 0;
  }

  .quotes {
    bottom: -80px;
    left: -5px;
  }
}

@media (--sk-large-viewport) {
  .main {
    padding: 120px 128px;
  }

  .descriptionFirstPart,
  .descriptionSecondPart {
    font-size: var(--sk-font-size-4); /* 20px */
  }

  .quotes {
    bottom: 0;
    left: 0;
  }
}

}
@layer base {
  .wrapper {
  --video-width: 382px;
  --video-height: 208px;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  max-width: var(--video-width);
  max-height: var(--video-height);
  border: 1px solid var(--sk-color-white);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-black);
  text-align: center;
  cursor: pointer;
}

.icon {
  position: absolute;
  z-index: var(--z-above-ui-kit-video-player);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnailWrapper {
  position: relative;
  margin: 0;
}

.thumbnail {
  display: block;
  width: 100%;
  max-width: var(--video-width);
  height: 100%;
  max-height: var(--video-height);
  border-radius: var(--sk-radius-12);
}

.videoWrapper {
  overflow: hidden;
}

.video {
  inset: 0;
}

.video iframe {
  width: 100%;
  max-width: var(--video-width);
  height: 100%;
  max-height: var(--video-height);
  border-radius: var(--sk-radius-12);
}

@media (--sk-large-viewport) {
  .wrapper {
    --video-width: 541px;
    --video-height: 309px;
    width: var(--video-width);
    height: var(--video-height);
  }

  .thumbnail {
    width: var(--video-width);
    height: var(--video-height);
  }

  .video iframe {
    width: var(--video-width);
    height: var(--video-height);
  }
}

}
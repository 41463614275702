@layer base {
  .wrapper {
  background-color: var(--sk-color-black);
}

.main {
  --layout-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--layout-size);
  padding: var(--sk-size-56) var(--sk-space-16);
  color: var(--sk-color-white);
}

.heading {
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  justify-content: center;
  width: 100%;
  max-width: 382px;
  margin: 0 auto;
  padding-left: 0;
  gap: var(--sk-space-12);
}

.cardItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--sk-size-56);
  padding: var(--sk-space-20) var(--sk-space-32);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  cursor: pointer;
  gap: 10px;
}

.cardItemWhite {
  background-color: var(--sk-color-white);
}

.cardItemGreen {
  background-color: var(--sk-color-green-500);
}

.cardItemGreen .link,
.cardItemWhite .link {
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-polysans);
  text-decoration: none;
}

.cardItemWhite svg,
.cardItemGreen svg {
  flex-shrink: 0;
}

/* Hide extra items for mobile (show only 3) */
.cardItemWhite:nth-child(n + 4) {
  display: none;
}

@media (--sk-medium-viewport) {
  .cardContainer {
    grid-template-columns: repeat(3, 1fr);
    max-width: 626px;
  }

  /* (show only 5 white cards) */
  .cardItemWhite:nth-child(n + 1):nth-child(-n + 5) {
    display: flex;
  }

  .cardItemWhite,
  .cardItemGreen {
    padding: var(--sk-space-32) var(--sk-space-40);
  }
}

@media (--sk-large-viewport) {
  .cardContainer {
    grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
    max-width: 1024px;
  }

  /* (show only 11 white cards) */
  .cardItemWhite:nth-child(n + 1):nth-child(-n + 11) {
    display: flex;
  }

  .cardItemWhite,
  .cardItemGreen {
    padding: var(--sk-space-32) var(--sk-space-20);
  }
}

}
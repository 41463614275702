@layer base {
  .main {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sk-space-64);
}

.section {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: var(--sk-space-32);
}

.title::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  color: var(--sk-color-green-500);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  margin: var(--sk-space-64) 0;
}

.cover {
  position: relative;
  min-height: 405px;
  background-position: top center;
  background-size: 85%;
}

.footer {
  display: inline-flex;
  gap: var(--sk-space-20);
  margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .main {
    flex-direction: row;
    padding-bottom: calc(var(--sk-space-80) + var(--sk-space-16));
  }

  .content {
    justify-content: center;
    max-width: 312px;
    margin-top: var(--sk-space-40);
  }

  .cover {
    background-position: top left;
    background-size: 480px auto;
  }
}

@media (--sk-large-viewport) {
  .main {
    position: relative;
    padding-bottom: calc(var(--sk-space-64) * 2);
  }

  .main::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 215px solid var(--sk-color-green-500);
    border-right: 155px solid rgb(0 0 0 / 0%);
  }

  .section {
    justify-content: flex-end;
  }

  .content {
    max-width: 364px;
    padding-right: 100px;
  }
}

}